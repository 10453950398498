import {
  SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT,
  SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR,
  SET_ADDRESS_LOOKUP_SHIPPING_RESULT,
  SET_ADDRESS_LOOKUP_BILLING_RESULT,
  SET_ADDRESS_LOOKUP_SHIPPING_ERROR,
  SET_ADDRESS_LOOKUP_BILLING_ERROR,
  SET_ADDRESS_LOOKUP_FETCHING,
  CLEAR_ADDRESS_LOOKUP,
} from './types'
import type {
  SetAddressLookupShippingAndBillingResultPayload,
  SetAddressLookupShippingAndBillingResultAction,
  SetAddressLookupShippingAndBillingErrorPayload,
  SetAddressLookupShippingAndBillingErrorAction,
  SetAddressLookupResultAction,
  SetAddressLookupFetchingAction,
  SetAddressLookupErrorAction,
  SetCleanAddressLookupAction,
} from './types'

export const setShippingAndBillingAddressResult = function(
  payload: SetAddressLookupShippingAndBillingResultPayload
): SetAddressLookupShippingAndBillingResultAction {
  return { type: SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT, payload }
}

export const setShippingAndBillingAddressError = function(
  payload: SetAddressLookupShippingAndBillingErrorPayload
): SetAddressLookupShippingAndBillingErrorAction {
  return { type: SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR, payload }
}

export const setShippingAddressResult = function(
  payload: any
): SetAddressLookupResultAction{
  return { type: SET_ADDRESS_LOOKUP_SHIPPING_RESULT, payload }
}

export const setBillingAddressResult = function(
  payload: any
): SetAddressLookupResultAction{
  return { type: SET_ADDRESS_LOOKUP_BILLING_RESULT, payload }
}

export const setFetching = function(
  payload: any
): SetAddressLookupFetchingAction{
  return { type: SET_ADDRESS_LOOKUP_FETCHING, payload }
}

export const setShippingAddressError = function(
  payload: any
): SetAddressLookupErrorAction{
  return { type: SET_ADDRESS_LOOKUP_SHIPPING_ERROR, payload }
}

export const setBillingAddressError = function(
  payload: any
): SetAddressLookupErrorAction{
  return { type: SET_ADDRESS_LOOKUP_BILLING_ERROR, payload }
}

export const clearAddressLookup = function(): SetCleanAddressLookupAction {
  return { type: CLEAR_ADDRESS_LOOKUP }
}