import { validateSail } from './util.cjs'
import { CouponCodeResult, Sail, ShippingInformation, Address } from './types'

export const addSailToCart = function(sail: Sail){
  validateSail(sail)
  return {
    type: 'ADD_SAIL',
    sail,
  }
}

export const deleteSailFromCart = function(sail: Sail){
  validateSail(sail)
  return {
    type: 'DELETE_SAIL',
    sail,
  }
}

export const updateSailInCart = function(sail: Sail){
  validateSail(sail)
  return {
    type: 'UPDATE_SAIL',
    sail,
  }
}

export const setStripePaymentIntent = function(stripePaymentIntent){
  return {
    type: 'SET_STRIPE_PAYMENT_INTENT',
    stripePaymentIntent,
  }
}

export const setShippingInformation = function(shippingInformation : ShippingInformation){
  return {
    type: 'SET_SHIPPING_INFORMATION',
    shippingInformation,
  }
}

export const setCart = (sails: Sail[]) => {
  sails.forEach((sail) => {
    validateSail(sail)
  })

  return {
    sails: sails,
    type: 'SET_CART',
  }
}

export const clearCart = () => {
  return {
    type: 'CLEAR_CART',
  }
}

export const clearStripePaymentIntent = function(){
  return {
    type: 'CLEAR_PAYMENT_INTENT',
  }
}

export const applySelfInstallDiscount = function(){
  return {
    type: 'APPLY_SELF_INSTALL_DISCOUNT',
  }
}

export const removeSelfInstallDiscount = function(){
  return {
    type: 'REMOVE_SELF_INSTALL_DISCOUNT',
  }
}

export const setCouponCode = function(couponCode: string){
  return {
    type: 'SET_COUPON_CODE',
    couponCode,
  }
}

export const setCouponCodeResult = function(couponCodeResult){
  return {
    type: 'SET_COUPON_CODE_RESULT',
    couponCodeResult,
  }
}

export const setCouponCodeFetching = function(value){
  return {
    type: 'SET_COUPON_CODE_FETCHING',
    value,
  }
}

export const setCouponCodeError = function(error){
  return {
    type: 'SET_COUPON_CODE_ERROR',
    error,
  }
}

export const setShippingAddress = function(address: Address){
  return {
    type: 'SET_SHIPPING_ADDRESS',
    address,
  }
}

export const setBillingAddress = function(address: Address){
  return {
    type: 'SET_BILLING_ADDRESS',
    address,
  }
}


export const setSelectedCouponCode = function(selectedCouponCodeResult: CouponCodeResult){
  return {
    type: 'SET_SELECTED_COUPON_CODE',
    selectedCouponCodeResult,
  }
}

export const setSelectedFreeWarrantyCouponCode = function(selectedCouponCodeResult: CouponCodeResult){
  return {
    type: 'SET_SELECTED_FREE_WARRANTY_COUPON_CODE',
    selectedCouponCodeResult
  }
}

export const setSelectedNoInstallationKitCouponCode = function(selectedCouponCodeResult: CouponCodeResult){
  return {
    type: 'SET_SELECTED_NO_INSTALLATION_KIT_COUPON_CODE',
    selectedCouponCodeResult
  }
}

export const clearSelectedFreeWarrantyCouponCode = function(){
  return {
    type: 'CLEAR_SELECTED_FREE_WARRANTY_COUPON_CODE',
  }
}

export const clearSelectedCouponCode = function(){
  return {
    type: 'CLEAR_SELECTED_COUPON_CODE',
  }
}

export const clearSelectedNoInstallationKitCouponCode = function(){
  return {
    type: 'CLEAR_SELECTED_NO_INSTALLATION_KIT_COUPON_CODE',
  }
}


export const clearExtendedWarranty = function(){
  return {
    type: 'CLEAR_EXTENDED_WARRANTY',
  }
}

export const addExtendedWarranty = function(){
  return {
    type: 'ADD_EXTENDED_WARRANTY',
  }
}

/**
 * Show WhatHappensNext dialog when the customer presses the button intentionally
 */
export const showWhatHappensNextDialog = function(){
  localStorage.setItem('hasSeenWhatHappensNextVideo', 'yes')

  return {
    type: 'SHOW_WHAT_HAPPENS_NEXT_DIALOG',
  }
}

export const hideWhatHappensNextDialog = function(){
  return {
    type: 'HIDE_WHAT_HAPPENS_NEXT_DIALOG',
  }
}