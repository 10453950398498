import * as React from 'react'
import reducer, { defaultState } from './reducer'
import {
  setShippingAddressResult,
  setBillingAddressResult,
  setShippingAddressError,
  setBillingAddressError,
  setFetching,
  clearAddressLookup,
  setShippingAndBillingAddressResult,
  setShippingAndBillingAddressError
} from './actions'
import { AddressValidationServiceContext } from './context'
import useAddressValidation from './hooks/useAddressValidation'
import { ShippingAndBillingAddress } from './types'

const { useReducer } = React

type Props = {
  children: React.ReactNode;
}

const AddressValidationService = (props: Props) => {
  const { children } = props
  const [reducerState, dispatch] = useReducer(reducer, defaultState)
  const { validateAddress } = useAddressValidation()

  const doAddressValidationLookup = async (params: ShippingAndBillingAddress) => {
    const {
      shippingAddress,
      billingAddress,
    } = params

    dispatch(setShippingAndBillingAddressError({
      shippingAddressError: null,
      billingAddressError: null,
    }))
    dispatch(setShippingAndBillingAddressResult({
      shippingAddressResult: null,
      billingAddressResult: null,
    }))
    dispatch(setFetching(true))

    let shippingAddressResult = null
    let billingAddressResult = null

    try {
      shippingAddressResult = await validateAddress(shippingAddress)
      dispatch(setShippingAddressResult(shippingAddressResult))
    } catch (error) {
      console.error(error)
      dispatch(setShippingAddressError(error))
      dispatch(setFetching(false))
      throw error
    }

    if (billingAddress) {
      try {
        billingAddressResult = await validateAddress(billingAddress)
        dispatch(setBillingAddressResult(billingAddressResult))
      } catch (error) {
        console.error(error)
        dispatch(setBillingAddressError(error))
        dispatch(setFetching(false))
        throw error
      }
    }

    dispatch(setFetching(false))

    return [
      shippingAddressResult,
      billingAddressResult,
    ]
  }
  
  return <AddressValidationServiceContext.Provider
    value={{
      dispatch,
      reducerState,
      doAddressValidationLookup,
      clearAddressLookup: () => dispatch(clearAddressLookup()),
    }}
  >
    <>
      {children}
    </>
  </AddressValidationServiceContext.Provider>
}

export default AddressValidationService
