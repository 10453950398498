import { 
  getFromLocalStorage, 
  wrapReducerWithStatePersistence,
} from '../../util/storage'

export const defaultState = getFromLocalStorage('user') || {
  user: null,
  jwt: '',
}

const reducer = wrapReducerWithStatePersistence(
  'user',
  (state = defaultState, action) => {

    switch (action.type){
    case 'SET_USER':
      state = Object.assign({}, state)
      state.user = action.user
      return state
    case 'SET_JWT':
      state = Object.assign({}, state)
      state.jwt = action.jwt
      return state
    case 'CLEAR_USER':
      state = Object.assign({}, state)
      state.user = null
      state.jwt = ''
      return state
    default:
    }

    return state
  }
)

export default reducer
