import * as React from 'react'
import { defaultState } from './reducer'

type UserContextResponse = {
  dispatch: () => void,
  reducerState: any,
}

const { createContext, useContext } = React

export const UserContext = createContext({ state: defaultState })

export const useUserContext = (): UserContextResponse => useContext(UserContext)