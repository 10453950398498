import { useCallback } from 'react'
import { Address } from '../../CartLogic/types'
import { AUSTRALIA_COUNTRY_CODE } from '../../../util/constants'

const useAddressValidation = () => {
  const validateAddress = useCallback(
    async (params: Address): Promise<any> => {
      const { line1, line2, city, state, postcode, country } = params

      if (country !== AUSTRALIA_COUNTRY_CODE) {
        throw new Error('Country must be AU')
      }

      if (!process.env.GATSBY_GOOGLE_API_KEY) {
        throw new Error('Google API key is missing in environment variables.')
      }

      const response = await fetch(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.GATSBY_GOOGLE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            address: {
              regionCode: country,
              postalCode: postcode,
              locality: state,
              sublocality: city,
              addressLines: [line1, line2]
            }
          })
        }
      )

      const result = await response.json()

      if (response.status != 200) {
        throw new Error(result.error?.message || 'Non 200 status code returned from Google\'s validate address endpoint.')
      }

      if (result.error) {
        throw new Error(result.error?.message || 'Google\'s validate address endpoint returned an error.')
      }

      return result
    }, []
  )

  return { validateAddress }
}

export default useAddressValidation
