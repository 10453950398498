import * as React from 'react'
import { defaultState } from './reducer'

const { createContext, useContext } = React

export const VideoPopupContext = createContext({ state: defaultState })

type VideoPopupContextResponse = {
  openVideoPopup: () => void,
}

export const useVideoPopupContext = (): VideoPopupContextResponse => useContext(VideoPopupContext)