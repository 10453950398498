import { Order, Sail } from '../CartLogic/types'
import { UpdateOrderProps, UpdateSailParams } from './types'


export const addOrder = function(order: Order){
  return {
    type: 'ADD_ORDER',
    order,
  }
}

export const setOrder = function(order: Order){
  return {
    type: 'SET_ORDER',
    order,
  }
}

export const updateOrder = function(orderId: number, order: UpdateOrderProps){
  return {
    type: 'UPDATE_ORDER',
    id: orderId,
    order,
  }
}


export const clearOrders = function(){
  return {
    type: 'CLEAR_ORDERS',
  }
}


export const updateSail = function(params: UpdateSailParams, sail: Sail){
  return {
    type: 'UPDATE_SAIL',
    orderId: params.orderId,
    sailId: params.sailId,
    sail,
  }
}

export const setSailUpdating = function(orderId, sailId, value){
  return {
    type: 'SET_SAIL_UPDATING',
    orderId,
    sailId,
    value,
  }
}

export const updateSailProps = function(params: UpdateSailParams, sail: Sail){
  return {
    type: 'UPDATE_SAIL_PROPS',
    orderId: params.orderId,
    sailId: params.sailId,
    sail,
  }
}

export const markManufacturerInvoiceAsPaid = function(orderId: string, paidDate: number){
  return {
    type: 'MARK_MANUFACTURER_INVOICE_AS_PAID',
    orderId,
    paidDate,
  }
}