interface ApiRequestOptions extends RequestInit {
  jwt: string;
}

//const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const apiRequest = async (endpoint: string, options: ApiRequestOptions) => {
  const { jwt, ...restOptions } = options


  //await delay(4000)

  const response = await fetch(process.env.GATSBY_API + endpoint, {
    ...restOptions,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
      ...(restOptions.headers || {}),
    },
  })

  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.message || 'API request failed')
  }

  return response.json()
}

export default apiRequest