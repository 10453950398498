import * as Sentry from '@sentry/browser'
import { useUserContext } from '../../components/UserLogic/context'
import apiRequest from '../../util/request'

export const useDeleteCartOption = () => {
  const { reducerState: userReducerState } = useUserContext()

  return async (optionKey: string) => {
    try {
      await apiRequest(`/cart/options/${optionKey}`, {
        method: 'DELETE',
        jwt: userReducerState.jwt
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error updating cart options:', error)
      throw error
    }
  }
}
