/** 
 * @fileOverview Keeping constants in a CommonJS file so they can be includes
 * on both the front and backend.
 */

const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.shadespot.app'
const IOS_APP_URL = 'https://apps.apple.com/app/shadespot/id6593688240'

const SOCIAL_FACEBOOK_LINK = 'https://facebook.com/weareshadespot'
const SOCIAL_YOUTUBE_LINK = 'https://www.youtube.com/@WeAreShadeSpot'
const SOCIAL_INSTAGRAM_LINK = 'https://instagram.com/weareshadespot'

/**
 * The installation kit cost exclusive of GST
 */
const INSTALLATION_KIT_COST = 40000

const GST_RATE = 0.1

/**
 * The cost for extended warranty per sail exclusive of GST
 */
const EXTENDED_WARRANTY_COST = 29545

const AUD_TO_USD_RATE = 0.65

const AUSTRALIA_COUNTRY_CODE = 'AU'

const WARRANTY_PAGE_PATH = '/warranty'
const GOODS_AND_SERVICES_AGREEMENT_PAGE_PATH = '/terms-and-conditions'
const PRIVACY_POLICY_PAGE_PATH = '/privacy-policy'

module.exports = {
  ANDROID_APP_URL,
  IOS_APP_URL,
  INSTALLATION_KIT_COST,
  GST_RATE,
  EXTENDED_WARRANTY_COST,
  AUD_TO_USD_RATE,
  WARRANTY_PAGE_PATH,
  GOODS_AND_SERVICES_AGREEMENT_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
  SOCIAL_FACEBOOK_LINK,
  SOCIAL_INSTAGRAM_LINK,
  SOCIAL_YOUTUBE_LINK,
  AUSTRALIA_COUNTRY_CODE
}