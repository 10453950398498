import { Address } from '../CartLogic/types'

export const SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT = 'SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT'
export const SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR = 'SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR'
export const SET_ADDRESS_LOOKUP_SHIPPING_RESULT = 'SET_ADDRESS_LOOKUP_SHIPPING_RESULT'
export const SET_ADDRESS_LOOKUP_BILLING_RESULT = 'SET_ADDRESS_LOOKUP_BILLING_RESULT'
export const SET_ADDRESS_LOOKUP_FETCHING = 'SET_ADDRESS_LOOKUP_FETCHING'
export const SET_ADDRESS_LOOKUP_SHIPPING_ERROR = 'SET_ADDRESS_LOOKUP_SHIPPING_ERROR'
export const SET_ADDRESS_LOOKUP_BILLING_ERROR = 'SET_ADDRESS_LOOKUP_BILLING_ERROR'
export const CLEAR_ADDRESS_LOOKUP = 'CLEAR_ADDRESS_LOOKUP'

export type ShippingAndBillingAddress = {
  shippingAddress: Address,
  billingAddress?: Address,
}

export type AddressLookupState = {
  shippingAddressResult: any,
  billingAddressResult: any,
  shippingAddressError: any,
  billingAddressError: any,
  fetching: boolean,
}

export type SetAddressLookupShippingAndBillingResultPayload = {
  shippingAddressResult: any
  billingAddressResult: any
}

export type SetAddressLookupShippingAndBillingResultAction = {
  type: typeof SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT
  payload: SetAddressLookupShippingAndBillingResultPayload
}

export type SetAddressLookupResultAction = {
  type: (
    typeof SET_ADDRESS_LOOKUP_SHIPPING_RESULT |
    typeof SET_ADDRESS_LOOKUP_BILLING_RESULT
  )
  payload: any
}

export type SetAddressLookupFetchingAction = {
  type: typeof SET_ADDRESS_LOOKUP_FETCHING
  payload: boolean
}

export type SetAddressLookupShippingAndBillingErrorPayload = {
  shippingAddressError: any
  billingAddressError: any
}

export type SetAddressLookupShippingAndBillingErrorAction = {
  type: typeof SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR
  payload: SetAddressLookupShippingAndBillingErrorPayload
}

export type SetAddressLookupErrorAction = {
  type: (
    typeof SET_ADDRESS_LOOKUP_SHIPPING_ERROR |
    typeof SET_ADDRESS_LOOKUP_BILLING_ERROR
    )
  payload: any
}

export type SetCleanAddressLookupAction = {
  type: typeof CLEAR_ADDRESS_LOOKUP
}

export type AddressLookupAction = (
  SetAddressLookupShippingAndBillingResultAction |
  SetAddressLookupResultAction |
  SetAddressLookupFetchingAction |
  SetAddressLookupShippingAndBillingErrorAction |
  SetAddressLookupErrorAction |
  SetCleanAddressLookupAction
)



