import {
  // Actions
  SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT,
  SET_ADDRESS_LOOKUP_SHIPPING_RESULT,
  SET_ADDRESS_LOOKUP_BILLING_RESULT,
  SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR,
  SET_ADDRESS_LOOKUP_SHIPPING_ERROR,
  SET_ADDRESS_LOOKUP_BILLING_ERROR,
  SET_ADDRESS_LOOKUP_FETCHING,
  CLEAR_ADDRESS_LOOKUP,

  // Other types
  AddressLookupAction,
  AddressLookupState
} from './types'

export const defaultState: AddressLookupState = {
  shippingAddressResult: null,
  billingAddressResult: null,
  shippingAddressError: null,
  billingAddressError: null,
  fetching: false,
}

const reducer = (
  state = defaultState,
  action: AddressLookupAction
) => {
  switch (action.type) {
  case SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_RESULT:
    return {
      ...state,
      shippingAddressResult: action.payload.shippingAddressResult,
      billingAddressResult: action.payload.billingAddressResult,
    }
  case SET_ADDRESS_LOOKUP_SHIPPING_RESULT:
    return { ...state, shippingAddressResult: action.payload }
  case SET_ADDRESS_LOOKUP_BILLING_RESULT:
    return { ...state, billingAddressResult: action.payload }
  case SET_ADDRESS_LOOKUP_FETCHING:
    return { ...state, fetching: action.payload }
  case SET_ADDRESS_LOOKUP_SHIPPING_AND_BILLING_ERROR:
    return {
      ...state,
      shippingAddressError: action.payload.shippingAddressError,
      billingAddressError: action.payload.billingAddressError,
    }
  case SET_ADDRESS_LOOKUP_SHIPPING_ERROR:
    return { ...state, shippingAddressError: action.payload }
  case SET_ADDRESS_LOOKUP_BILLING_ERROR:
    return { ...state, billingAddressError: action.payload }
  case CLEAR_ADDRESS_LOOKUP:
    return defaultState
  default:
    return state
  }
}

export default reducer
