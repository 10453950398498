import * as React from 'react'
import { AddressLookupState, AddressLookupAction } from './types'

const { createContext, useContext } = React

type AddressValidationServiceContextType = {
  doAddressValidationLookup: (params: any) => Promise<any>
  reducerState: AddressLookupState
  clearAddressLookup: () => void
  dispatch: React.Dispatch<AddressLookupAction>
}

export const AddressValidationServiceContext = createContext<AddressValidationServiceContextType | undefined>(undefined)

export const useAddressValidationServiceContext = (): AddressValidationServiceContextType => {
  const context = useContext(AddressValidationServiceContext)

  if (!context) {
    throw new Error('useAddressValidationServiceContext must be used within an AddressValidationService')
  }

  return context
}