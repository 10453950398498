import * as Sentry from '@sentry/browser'
import { useUserContext } from '../../components/UserLogic/context'
import apiRequest from '../../util/request'

export const useClearCart = () => {
  const { reducerState: userReducerState } = useUserContext()

  return async () => {
    try {
      await apiRequest('/cart', {
        method: 'DELETE',
        jwt: userReducerState.jwt,
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error clearing cart options:', error)
      throw error
    }
  }
}
