import { Order } from './../CartLogic/types'

export type OrderReducerState = {
  orders: Order[],
}

export const defaultState = {
  orders: [],
} as OrderReducerState

const reducer = (state: OrderReducerState = defaultState, action) => {

  switch (action.type){
  case 'ADD_ORDER':
  case 'SET_ORDER':
    state = Object.assign({}, state)
    state.orders = state.orders.slice(0)
    var orderIndex = state.orders.findIndex(o => o.id == action.order.id)
    if (orderIndex == -1){
      state.orders.push(action.order)
    } else {
      state.orders[orderIndex] = action.order
    }
    // Keep it sorted by creation data, where most recent is first
    state.orders.sort((a, b) => {
      if (a.createdAt < b.createdAt)
        return 1
      if (a.createdAt > b.createdAt)
        return -1
      return 0
    })
    return state
  case 'UPDATE_ORDER':
    state = Object.assign({}, state)
    state.orders = state.orders.slice(0)
    var orderIndex = state.orders.findIndex(o => o.id == action.id)
    if (orderIndex == -1){
      throw new Error(`Order not found with id ${action.id})`)
    } 
    var existingOrder = state.orders[orderIndex]
    state.orders[orderIndex] = Object.assign({}, existingOrder, action.order)
    return state
  case 'CLEAR_ORDERS': 
    return {
      orders: [],
    }
  case 'UPDATE_SAIL':
    var orderIndex = state.orders.findIndex(o => o.id == action.orderId)
    if (orderIndex == -1){
      throw new Error(`Order not found with id ${action.orderId})`)
    } 
    var existingOrder = state.orders[orderIndex]

    var sailIndex = existingOrder.sails.findIndex(s => s.sail.id == action.sailId)
    if (sailIndex == -1){
      throw new Error(`Sail not found with id ${action.sailId})`)
    } 
    var existingSail = existingOrder.sails[sailIndex]
    var newSail = Object.assign({}, existingSail, action.sail)
    var newOrder = Object.assign({}, existingOrder)
    newOrder.sails = newOrder.sails.slice(0)
    newOrder.sails[sailIndex] = newSail
    state = Object.assign({}, state)
    state.orders = state.orders.slice(0)
    state.orders[orderIndex] = newOrder
    return state
  case 'UPDATE_SAIL_PROPS':
    var orderIndex = state.orders.findIndex(o => o.id == action.orderId)
    if (orderIndex == -1){
      throw new Error(`Order not found with id ${action.orderId})`)
    } 
    var existingOrder = state.orders[orderIndex]

    var sailIndex = existingOrder.sails.findIndex(s => s.sail.id == action.sailId)
    if (sailIndex == -1){
      throw new Error(`Sail not found with id ${action.sailId})`)
    } 
    var existingSail = existingOrder.sails[sailIndex]
    var newSail = Object.assign({}, existingSail)
    newSail.sail = Object.assign({}, newSail.sail, action.sail)
    var newOrder = Object.assign({}, existingOrder)
    newOrder.sails = newOrder.sails.slice(0)
    newOrder.sails[sailIndex] = newSail
    state = Object.assign({}, state)
    state.orders = state.orders.slice(0)
    state.orders[orderIndex] = newOrder
    return state
  case 'SET_SAIL_UPDATING':
    var { orderId, sailId, value } = action
    var stateClone = Object.assign({}, state)
    var loadingStateClone = stateClone.loadingState = Object.assign({}, stateClone.loadingState)
    var ordersState = loadingStateClone.orders = Object.assign({}, loadingStateClone.orders)
    var orderState = ordersState[orderId] = Object.assign({}, ordersState[orderId])
    var sailsState = orderState.sails = Object.assign({}, orderState.sails)
    var sailState = sailsState[sailId] = Object.assign({}, sailsState[sailId])
    sailState.loading = value
    return stateClone
  case 'MARK_MANUFACTURER_INVOICE_AS_PAID':
    var orderIndex = state.orders.findIndex(o => o.id == action.orderId)
    if (orderIndex == -1){
      throw new Error(`Order not found with id ${action.orderId})`)
    } 
    state = Object.assign({}, state)
    state.orders = state.orders.slice(0)
    var order = state.orders[orderIndex]
    state.orders[orderIndex] = {
      ...order,
      manufacturerPaidDate: action.paidDate,
    }
    return state
  default: 
  }

  return state
}

export default reducer
