import * as React from 'react'
import { defaultState, CartReducerState } from './reducer'
import { Sail, ShippingInformation, Address } from './types'

const { createContext, useContext } = React

type CartContextResponse = {
  addSailToCart: (sail: Sail) => void,
  deleteSailFromCart: (sail: Sail) => void
  updateSailInCart: (sail: Sail) => void,
  setShippingInformation: (shippingInformation: ShippingInformation) => void,
  dispatch: () => void,
  reducerState: CartReducerState,
  setCart: (sails: Sail[]) => void,
  clearCart: () => void,
  setShippingAddress: (address: Address) => void,
  setBillingAddress: (address: Address) => void,
  clearExtendedWarranty: () => void,
  updateCouponCode: (discountCode: string) => void,
  clearSelectedCouponCode: () => void,
  showWhatHappensNextDialog: () => void,
  hideWhatHappensNextDialog: () => void,
  applySelfInstallDiscount: () => void,
}

export const CartContext = createContext({ reducerState: defaultState })

export const useCartContext = (): CartContextResponse => useContext(CartContext)