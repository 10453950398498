import { 
  getFromLocalStorage, 
  wrapReducerWithStatePersistence,
} from '../../util/storage'
import { Sail, ShippingInformation, CouponCodeResult } from './types'

export type CartReducerState = {
  sails: Sail[],
  stripePaymentIntent: any,
  shippingInformation: ShippingInformation,
  orderNumber?: string,
  orderId?: string,
  selfInstallDiscountApplied: boolean,
  couponCode?: string,
  couponCodeResult: any,
  couponCodeFetching: boolean,
  couponCodeError: Error,
  /**
   * This is the standard checkout coupon code that either is a percentage
   * off or flat rate off.
   */
  selectedCouponCodeResult?: CouponCodeResult,
  /** 
   * This indicates the user gets a free warranty and a discount for that 
   * warranty is applied.
   */
  selectedFreeWarrantyCouponCodeResult?: CouponCodeResult,
  /**
   * If specified then the user has entered a coupon code that specifies 
   * they do not wish to recieve an installation kit.
   */
  selectedNoInstallationKitCouponCodeResult?: CouponCodeResult,

  /**
   * True if the extended warranty is applied.
   */
  extendedWarrantyApplied: boolean,

  /**
   * What happens next popup flag
   */
  isWhatHappensNextDialogVisible: boolean,
}

const DEFAULT_CART_STATE = {
  sails: [],
  extendedWarrantyApplied: true,
  isWhatHappensNextDialogVisible: false
}

export const defaultState = getFromLocalStorage('cart') || DEFAULT_CART_STATE as CartReducerState

const reducer = wrapReducerWithStatePersistence(
  'cart',
  (state: CartReducerState = defaultState, action) => {
    switch (action.type){
    case 'ADD_SAIL':
      state = Object.assign({}, state)
      state.sails = state.sails.slice(0)
      state.sails.push(action.sail)
      return state
    case 'UPDATE_SAIL':
      state = Object.assign({}, state)
      state.sails = state.sails.slice(0)
      var sailIndex = state.sails.findIndex(s => s.id == action.sail.id)
      if (sailIndex == -1)
        throw new Error('Sail not found.')
      state.sails[sailIndex] = action.sail
      return state
    case 'DELETE_SAIL':
      state = Object.assign({}, state)
      state.sails = state.sails.filter(s => s.id != action.sail.id)
      return state
    case 'SET_STRIPE_PAYMENT_INTENT':
      state = Object.assign({}, state)
      state.stripePaymentIntent = action.stripePaymentIntent
      return state
    case 'SET_SHIPPING_INFORMATION': {
      state = Object.assign({}, state)
      state.shippingInformation = action.shippingInformation
      return state
    }
    case 'SET_ORDER_ID': {
      state = Object.assign({}, state)
      state.orderId = action.orderId
      state.orderNumber = action.orderNumber
      return state
    }
    case 'SET_CART':
      return {
        ...state,
        sails: action.sails,
      }
    case 'CLEAR_CART':
      return JSON.parse(JSON.stringify(DEFAULT_CART_STATE))
    case 'CLEAR_PAYMENT_INTENT':
      state = Object.assign({}, state)
      delete state.stripePaymentIntent
      return state
    case 'APPLY_SELF_INSTALL_DISCOUNT':
      state = Object.assign({}, state)
      state.selfInstallDiscountApplied = true
      return state
    case 'REMOVE_SELF_INSTALL_DISCOUNT':
      state = Object.assign({}, state)
      state.selfInstallDiscountApplied = false
      return state
    case 'SET_COUPON_CODE':
      state = Object.assign({}, state)
      state.couponCode = action.couponCode
      return state
    case 'SET_COUPON_CODE_RESULT':
      state = Object.assign({}, state)
      state.couponCodeResult = action.couponCodeResult
      return state
    case 'SET_COUPON_CODE_FETCHING':
      state = Object.assign({}, state)
      state.couponCodeFetching = action.value
      return state
    case 'SET_COUPON_CODE_ERROR':
      state = Object.assign({}, state)
      state.couponCodeError = action.error
      return state
    case 'SET_SHIPPING_ADDRESS':
      state = Object.assign({}, state)
      state.shippingInformation = Object.assign({}, state.shippingInformation)
      state.shippingInformation.address = action.address
      return state
    case 'SET_BILLING_ADDRESS':
      state = Object.assign({}, state)
      state.shippingInformation = Object.assign({}, state.shippingInformation)
      state.shippingInformation.billingAddress = action.address
      return state
    case 'SET_SELECTED_FREE_WARRANTY_COUPON_CODE':
      state = Object.assign({}, state)
      state.selectedFreeWarrantyCouponCodeResult = action.selectedCouponCodeResult
      state.couponCode = ''
      return state
    case 'SET_SELECTED_NO_INSTALLATION_KIT_COUPON_CODE':
      state = Object.assign({}, state)
      state.selectedNoInstallationKitCouponCodeResult = action.selectedCouponCodeResult
      state.couponCode = ''
      return state
    case 'SET_SELECTED_COUPON_CODE':
      state = Object.assign({}, state)
      state.selectedCouponCodeResult = action.selectedCouponCodeResult
      state.couponCode = ''
      return state
    case 'CLEAR_SELECTED_COUPON_CODE':
      state = Object.assign({}, state)
      delete state.selectedCouponCodeResult
      return state
    case 'CLEAR_SELECTED_FREE_WARRANTY_COUPON_CODE':
      state = Object.assign({}, state)
      delete state.selectedFreeWarrantyCouponCodeResult
      return state
    case 'CLEAR_SELECTED_NO_INSTALLATION_KIT_COUPON_CODE':
      state = Object.assign({}, state)
      delete state.selectedNoInstallationKitCouponCodeResult
      return state
    case 'CLEAR_EXTENDED_WARRANTY':
      state = Object.assign({}, state)
      state.extendedWarrantyApplied = false
      return state
    case 'ADD_EXTENDED_WARRANTY':
      state = Object.assign({}, state)
      state.extendedWarrantyApplied = true
      return state
    case 'SHOW_WHAT_HAPPENS_NEXT_DIALOG':
      state = Object.assign({}, state)
      state.isWhatHappensNextDialogVisible = true
      return state
    case 'HIDE_WHAT_HAPPENS_NEXT_DIALOG':
      state = Object.assign({}, state)
      state.isWhatHappensNextDialogVisible = false
      return state
    }

    return state
  }
)

export default reducer
