import React, { useState, useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import { useUserContext } from '../../components/UserLogic/context'
import apiRequest from '../../util/request'
import {
  setCart,
  clearCart,
  setSelectedFreeWarrantyCouponCode,
  clearSelectedFreeWarrantyCouponCode,
  setSelectedCouponCode,
  clearSelectedCouponCode,
  setSelectedNoInstallationKitCouponCode,
  clearSelectedNoInstallationKitCouponCode,
  addExtendedWarranty,
  clearExtendedWarranty
} from '../../components/CartLogic/actions'
import { Cart } from '../../components/CartLogic/types'

interface UseInitializeCartReturn {
  initializeCart: () => Promise<void>
  isLoading: boolean
  initialized: boolean
  error: Error | null
}

export const useInitializeCart = (
  dispatch: React.Dispatch<any>
): UseInitializeCartReturn => {
  const { reducerState: userReducerState } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const initializeCart = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    setError(null)

    try {
      // Check if user is authenticated
      if (!userReducerState.jwt) {
        dispatch(clearCart())
        setInitialized(true)
        setIsLoading(false)
        return
      }

      const response = await apiRequest('/cart', {
        method: 'GET',
        jwt: userReducerState.jwt,
      })

      const { cart: { sails, options } }: { cart: Cart } = response

      if (!sails || sails.length === 0) {
        dispatch(clearCart())
      } else {
        dispatch(setCart(sails))
        dispatch(clearSelectedFreeWarrantyCouponCode())
        dispatch(clearSelectedCouponCode())
        dispatch(clearSelectedNoInstallationKitCouponCode())
        dispatch(addExtendedWarranty())

        if (options.extendedWarranty) {
          options.extendedWarranty.isActive ?
            dispatch(addExtendedWarranty()) :
            dispatch(clearExtendedWarranty())
        }

        if (options.freeWarranty) {
          dispatch(setSelectedFreeWarrantyCouponCode({ couponCode: options.freeWarranty }))
        }

        if (options.couponCode) {
          dispatch(setSelectedCouponCode({ couponCode: options.couponCode }))
        }

        if (options.noInstallationKit) {
          dispatch(setSelectedNoInstallationKitCouponCode({ couponCode: options.noInstallationKit }))
        }
      }

      setInitialized(true)
    } catch (err) {
      Sentry.captureException(err)
      console.error('Error initializing cart:', err)
      setError(err as Error)
      dispatch(clearCart())
    } finally {
      setIsLoading(false)
    }
  }, [dispatch, userReducerState.jwt])

  return { initializeCart, isLoading, initialized, error }
}
